import React from "react";
import {
  MdMailOutline,
  MdOutlineLocationOn,
  MdOutlineMail,
  MdOutlinePhone,
} from "react-icons/md";
import { FaFacebookF, FaLinkedinIn, FaInstagram } from "react-icons/fa";
import { IMAGES } from "../stores";
import { Link, useNavigate } from "react-router-dom";
import Signup from "../pages/auth/Signup";
import { BsWhatsapp } from "react-icons/bs";

function Footer() {
  const jumpToRegister = () => {
    <Signup />;
  };

  const navigate = useNavigate();

  return (
    <>
      <div className="relative font-Helvetica">
        <div className="font-Helvetica  relative bg-[#000322] w-full ">
          <div className="font-Helvetica  lg:px-10 md:px-10 px-2 pb-5  pt-10 mx-auto container">
            <div className="font-Helvetica  grid grid-cols-1 lg:grid-cols-5 md:grid-cols-2 gap-5 pb-10 ">
              <div className="font-Helvetica lg:flex lg:justify-start">
                <div className="font-Helvetica  space-y-5">
                  <Link to="/">
                    <img
                      src={IMAGES.WhiteLogo}
                      alt=""
                      className="font-Helvetica  w-32 cursor-pointer"
                    />
                  </Link>

                  <div className="font-Helvetica  flex space-x-3 lg:justify-start md:justify-start justify-start">
                    <div className="font-Helvetica  border border-white rounded-full p-1.5 cursor-pointer">
                      <FaFacebookF
                        color="white"
                        size={15}
                        className="font-Helvetica  "
                      />
                    </div>
                    <div className="font-Helvetica  border border-white rounded-full p-1.5 cursor-pointer">
                      <FaLinkedinIn
                        color="white"
                        size={15}
                        className="font-Helvetica  "
                      />
                    </div>
                    <div className="font-Helvetica  border border-white rounded-full p-1.5 cursor-pointer">
                      <FaInstagram
                        color="white"
                        size={15}
                        className="font-Helvetica  "
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="font-Helvetica  lg:flex lg:justify-start lg:ml-[-15px]">
                <div className="font-Helvetica  space-y-5">
                  <p className="font-Helvetica  font-QuickStand text-white text-2xl font-medium">
                    Quick Links
                    <p className="font-Helvetica  border-b-[3px] border-legavacYellow w-12 hover:w-28 transition-all duration-500 pt-1"></p>
                  </p>
                  <ul className="font-Helvetica  space-y-2">
                    <li className="font-Helvetica   text-white text-sm opacity-80 hover:opacity-100 cursor-pointer">
                      <a href="/"> Home</a>
                    </li>
                    <li className="font-Helvetica text-white text-sm opacity-80 hover:opacity-100 cursor-pointer">
                      <a href="/about-us"> About Us</a>
                    </li>
                    <li className="font-Helvetica text-white text-sm opacity-80 hover:opacity-100 cursor-pointer">
                      <a href="/ServiceWeOffer"> Service</a>
                    </li>
                    <li className="font-Helvetica text-white text-sm opacity-80 hover:opacity-100 cursor-pointer">
                      <a href="https://wa.link/67a9hm" target="_blank">
                        {" "}
                        Contact Us
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="font-Helvetica   text-white space-y-5 lg:ml-[-40px]">
                <p className="font-Helvetica  font-QuickStand text-white  text-2xl font-medium">
                  Get In Touch
                  <p className="font-Helvetica  border-b-[3px] border-legavacYellow w-12 hover:w-24 transition-all duration-500 pt-1"></p>
                </p>
                <ul className="font-Helvetica  space-y-2">
                  <li className="font-Helvetica  flex space-x-2 opacity-80">
                    <div className="font-Helvetica  ">
                      <MdOutlineLocationOn
                        color="white"
                        size={25}
                        className="font-Helvetica  "
                      />
                    </div>
                    <div className="md:pr-10">
                      <p className="font-Helvetica  text-sm">
                        <span className="font-Helvetica  text-md font-medium tracking-[1px]">
                          Head Office -
                        </span>{" "}
                        Office No-1208 12th Floor, International Trade Centre,
                        Kamdhenu, Sector-14, Kharghar, Navi Mumbai - 410210
                      </p>
                    </div>
                  </li>

                  <li className="font-Helvetica  flex space-x-2 opacity-80 hover:opacity-100 items-center">
                    <div>
                      <MdOutlinePhone color="white" size={25} />
                    </div>
                    <div>
                      <p className="font-Helvetica  text-sm ">
                        +91 86559 20593
                      </p>
                    </div>
                  </li>
                  <li className="font-Helvetica  flex space-x-2 opacity-80 hover:opacity-100 items-center">
                    <div>
                      <MdMailOutline color="white" size={25} />
                    </div>
                    <div>
                      <p className="font-Helvetica  text-sm cursor-pointer">
                        info@legavac.com
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="font-Helvetica  lg:flex lg:justify-center  lg:ml-[-20px] ">
                <div className="font-Helvetica  space-y-5">
                  <p className="font-Helvetica  font-QuickStand text-white text-2xl font-medium">
                    Other Links
                    <p className="font-Helvetica  border-b-[3px] border-legavacYellow w-12 hover:w-28 transition-all duration-500 pt-1"></p>
                  </p>
                  <ul className="font-Helvetica  space-y-2">
                    <li
                      className="font-Helvetica text-white text-sm opacity-80 hover:opacity-100 cursor-pointer"
                      onClick={() => {
                        navigate("/PrivacyPolicy");
                      }}
                    >
                      Privacy & Policy
                    </li>
                    <li className="font-Helvetica text-white text-sm opacity-80 hover:opacity-100 cursor-pointer">
                      Terms of Services
                    </li>
                  </ul>
                </div>
              </div>
              <div className="font-Helvetica  text-white space-y-5">
                <p className="font-Helvetica  font-QuickStand text-white text-2xl font-medium">
                  Our Product
                  <p className="font-Helvetica  border-b-[3px] border-legavacYellow w-12 hover:w-32 transition-all duration-500 pt-1"></p>
                </p>
                <div className="font-Helvetica  space-y-2">
                  <p className="font-Helvetica  text-sm opacity-80">
                    Browse Your Job Listing Now ..!
                  </p>
                  <div className="flex">
                    <img src={IMAGES.LD} className="h-40" />
                    <img src={IMAGES.WelcomeBack} className="h-40" />
                  </div>
                  <button
                    type="button"
                    data-te-ripple-init
                    data-te-ripple-color="light"
                    className="font-Helvetica  flex justify-center cursor-pointer items-center space-x-2 bg-legavacYellow w-full p-2 rounded-sm text-sm text-white font-semibold transition duration-150 ease-in-out"
                    onClick={jumpToRegister}
                  >
                    <a href="https://wa.link/67a9hm" target="_blank">
                      Get In Touch
                    </a>
                  </button>
                </div>
              </div>
            </div>

            <hr className="font-Helvetica  opacity-30" />

            <div className="font-Helvetica  md:flex md:justify-between grid place-items-center lg:space-y-0 md:space-y-0 space-y-5 pt-5">
              <p className="font-Helvetica   text-white text-sm opacity-80 md:text-left text-center">
                © {new Date().getFullYear()}{" "}
                <span className="font-Helvetica  underline cursor-pointer text-white text-md">
                  LegaVac
                </span>{" "}
                All rights reserved. Design & Developed by{" "}
                <span className="font-Helvetica  underline cursor-pointer text-white text-md">
                  <a href="https://www.dthrill.com/" target="_blank">
                    DThrill
                  </a>
                </span>
                .
              </p>
              <p className="font-Helvetica  text-sm text-white opacity-80 hover:opacity-100 cursor-pointer">
                Cookie Policy
              </p>
            </div>
          </div>
        </div>
        <a href="https://wa.link/67a9hm" target="_about">
          <BsWhatsapp className="fixed z-50 bg-[#25d366] text-white rounded-full lg:bottom-24 md:bottom-24 bottom-24 md:right-[18px] right-[25px] w-16 h-16" />
        </a>
      </div>
    </>
  );
}

export default Footer;
