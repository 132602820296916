import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Signup from "../pages/auth/Signup";
import { IMAGES } from "../stores";
import { Carousel, initTE } from "tw-elements";

function AdvSearchSection() {
  initTE({ Carousel });
  // var TxtRotate = function (el, toRotate, period) {
  //   this.toRotate = toRotate;
  //   this.el = el;
  //   this.loopNum = 0;
  //   this.period = parseInt(period, 10) || 2000;
  //   this.txt = "";
  //   this.tick();
  //   this.isDeleting = false;
  // };

  // TxtRotate.prototype.tick = function () {
  //   var i = this.loopNum % this.toRotate.length;
  //   var fullTxt = this.toRotate[i];

  //   if (this.isDeleting) {
  //     this.txt = fullTxt.substring(0, this.txt.length - 1);
  //   } else {
  //     this.txt = fullTxt.substring(0, this.txt.length + 1);
  //   }

  //   this.el.innerHTML =
  //     '<span className="font-Helvetica  wrap">' + this.txt + "</span>";

  //   var that = this;
  //   var delta = 300 - Math.random() * 100;

  //   if (this.isDeleting) {
  //     delta /= 2;
  //   }

  //   if (!this.isDeleting && this.txt === fullTxt) {
  //     delta = this.period;
  //     this.isDeleting = true;
  //   } else if (this.isDeleting && this.txt === "") {
  //     this.isDeleting = false;
  //     this.loopNum++;
  //     delta = 500;
  //   }

  //   setTimeout(function () {
  //     that.tick();
  //   }, delta);
  // };

  // window.onload = function () {
  //   var elements = document.getElementsByClassName("txt-rotate");
  //   for (var i = 0; i < elements.length; i++) {
  //     var toRotate = elements[i].getAttribute("data-rotate");
  //     var period = elements[i].getAttribute("data-period");
  //     if (toRotate) {
  //       new TxtRotate(elements[i], JSON.parse(toRotate), period);
  //     }
  //   }
  //   // INJECT CSS
  //   var css = document.createElement("style");
  //   css.type = "text/css";
  //   css.innerHTML = ".txt-rotate > .wrap { border-right: 0.08em solid #666 }";
  //   document.body.appendChild(css);
  // };

  const [file, setFile] = useState(null);

  const handleFileUpload = (event) => {
    const uploadedFile = event.target.files[0];
    setFile(uploadedFile);
  };

  return (
    <>
      <div className="font-Helvetica  relative pt-20">
        <div className="font-Helvetica bg-[url('/src/assets/images/litigation-slider2.jpg')]  w-full lg:h-[80vh] md:h-[50vh] h-auto bg-no-repeat lg:bg-[center_left_5] lg:bg-cover md:bg-cover bg-contain">
          <div className="font-Helvetica lg:w-3/5 md:w-auto w-auto lg:px-10 md:px-10 px-5 flex lg:justify-center md:justify-start items-center lg:h-[80vh] md:h-[50vh]">
            <div className="font-Helvetica py-10">
              <p className="font-QuickStand lg:pb-5 text-white lg:text-5xl md:text-3xl text-xl font-bold text-center">
                Welcome To{" "}
                <span className="font-QuickStand">
                  LegaVac Services Private Limited, your trusted partner in
                  legal solutions. we are dedicated to empowering individuals
                  and businesses with our specialized offerings: FIR
                  Registration, Litigation Management, and Legal Detrum - the
                  world's largest network of lawyers.
                </span>
              </p>
              <div className="font-Helvetica   grid place-content-center lg:space-y-5 md:space-y-10 space-y-5">
                {/* <label
                  data-te-ripple-init
                  data-te-ripple-color="light"
                  className="font-Helvetica w-fit text-center text-sm cursor-pointer bg-transparent border border-white tracking-wider shadow shadow-slate-600 text-white font-semibold rounded py-2 px-5 transition duration-150 ease-in-out"
                  for="file_input"
                >
                  Upload Resume
                </label> */}
                <label>
                  {/* <p className="lg:text-3xl text-sm text-center  text-white pb-5">
                    Launch Our New Product..!!
                  </p> */}
                  {/* <p className="text-white lg:text-4xl animate-gradientx bg-gradient-to-r  from-legavacBlue to-legavacYellow  w-fit font-bold lg:p-5 md:p-2 p-2 tracking-wide ">
                    Legal Detrum
                  </p> */}
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="font-Helvetica  invisible fixed bottom-0 top-0 right-0 z-[1045] flex w-full max-w-full translate-x-full flex-col border-none bg-white bg-clip-padding text-neutral-700 shadow-sm outline-none transition duration-300 ease-in-out dark:bg-neutral-800 dark:text-neutral-200 [&[data-te-offcanvas-show]]:transform-none"
        tabIndex="-1"
        id="offcanvasRight"
        aria-labelledby="offcanvasRightLabel"
        data-te-offcanvas-init
      >
        <Signup />
      </div>
    </>
  );
}

export default AdvSearchSection;
